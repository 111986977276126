<template>
  <section class="p-0 o-hidden">
    <div class="container min-vh-100 d-flex flex-column justify-content-between text-center pt-4 py-md-5">
      <div class="fade-page">
        <img src="../../assets/jumpstart/img/logos/hostomate.svg" width="150px;">
      </div>
      <div class="mt-5">
        <div class="row justify-content-center">
          <div class="col-5 mb-4">
            <img src="../../assets/img/errors/423_Locked.jpg" alt="423 Page Locked" class="img-fluid">
          </div>
          <div class="col-12">
            <h1>423 - Page Locked</h1>

            <div class="lead">
              Whoops, it looks like the page you requested isn't available anymore.
              <br>
              If you have any questions regarding your stay, you can contact your host.
            </div>


            <div>
              <h4 class="mb-1 mt-5">{{$store.state.booking.property.name}}</h4>
              <address class="lead mb-4">
                <p>{{$store.state.booking.property.address.street}}</p>
                <p>
                  {{$store.state.booking.property.address.postalCode}}
                  {{$store.state.booking.property.address.city}}
                </p>
                <p v-if="$store.state.booking.property.address.state">
                  {{$store.state.booking.property.address.state}}
                </p>
                <p>{{propertyCountry.name}}</p>
              </address>
              
              <div class="mb-4">
                <h5 class="mb-1">Email us</h5>
                <a v-bind:href="'mailto:' + $store.state.booking.property.contact.email" class="lead">
                  {{$store.state.booking.property.contact.email}}
                </a>
              </div>
              <div class="mb-4">
                <h5 class="mb-1">Call any time</h5>
                <a v-bind:href="'tel:' + $store.state.booking.property.contact.phone" class="lead">
                  {{$store.state.booking.property.contact.phone}}
                </a>
              </div>
            </div>


          </div>
        </div>
      </div>
      <div>
        <a href="index.html" class="btn btn-sm btn-outline-light fade-page">Back to Home</a>
      </div>
    </div>
  </section>
</template>

<script>
const countryList = require('country-list');

export default {
  name: 'Locked',
  data: () => ({
    countries: countryList.getData()
  }),
  computed: {
    propertyCountry: function(){
      return this.countries.find(e => e.code == this.$store.state.booking.property.address.country);
    }
  },
}
</script>
